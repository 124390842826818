import React from 'react'

import SEO from '../components/SEO'
import PageTitle from '../components/PageTitle'
import aboutImg from '../images/option_5.jpg'
import aboutSecondImg from '../images/option_3.jpg'
import aboutThirdImg from '../images/option_1.jpg'

const AboutPage = () => (
  <>
    <SEO title="Matys carpentry - Professional and comprehensives services in the construction business." />
    <div className="mt-32"></div>
    <PageTitle>Nice to Meet You!</PageTitle>
    <div className="max-w-xl mx-auto px-6 mb-24">
      <div className="flex items-center flex-col md:flex-row">
        <div
          style={{
            borderRadius: `100%`,
            minHeight: 270,
            width: 250,
            minWidth: 250,
            backgroundPositionY: -40,
            backgroundSize: 'cover',
            backgroundImage: `url(${aboutImg})`
          }}
          className="mb-8 md:mb-0 md:ml-8"
        />
        <div className={'text-lg md:text-xl text-black md:px-16'}>
          My name is Greg Matys and I’m the founder of Matys Carpentry. Twenty
          years ago, I started my business servicing Fairfield County,
          Connecticut as a Project Manager on a number of exquisite home
          renovations.
        </div>
      </div>
      <div className=" h-12 " />

      <p className="text-lg md:text-xl text-black">
        A while ago, I started to specialize in custom built furniture and
        bespoke designs for cabinets and furniture. A decade later, I’ve
        developed my business to offer full-service carpentry and construction,
        so that every aspect of your renovation, remodeling, or complete
        reconstruction is an absolute realization of your dreams. And the best
        part is that improvements to your home exponentially boost their value
        and with Matys Carpentry, you can count on a finished product that
        exceeds your expectations.
      </p>
      <div className="h-8" />
      <p className="text-lg md:text-xl text-black">
        Trust me with your home and you can expect high-end and locally sourced
        materials so that whether it’s a kitchen or bath remodel, the
        refinishing of your interior and exterior walls, or custom work on
        tiles, trim, moldings, and more, Matys Carpentry will deliver on time,
        and on budget.
      </p>
      <div className="h-12 md:h-32" />
      <div className="flex justify-center items-center flex-col md:flex-row">
        <div
          style={{
            borderRadius: `5%`,
            minHeight: 330,
            width: 250,
            minWidth: 250,
            backgroundPositionY: -20,
            backgroundSize: 'cover',
            backgroundImage: `url(${aboutSecondImg})`
          }}
          className="mb-12 md:mb-0 md:mr-16 w-full"
        />
        <div
          style={{
            borderRadius: `5%`,
            minHeight: 330,
            width: 250,
            minWidth: 250,
            backgroundSize: 'cover',
            backgroundImage: `url(${aboutThirdImg})`
          }}
          className="md:mr-16"
        />
      </div>
    </div>
  </>
)

export default AboutPage
